import Tabs from '@/components/Tabs/Tabs'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Root, RootOrderFilter } from './TopFilters.style'
import ChevronRight from '@/components/Icons/ChevronRight'

const TopFilters = ({ filterBuild, setFilterBuild }) => {
    return (
        <Root>
            <Tabs
                value={filterBuild}
                setValue={setFilterBuild}
                names={['CASA', 'DEPARTAMENTO', 'TERRENO']}
            />
            <RootOrderFilter>
                <FormControl fullWidth className='select-style-gray'>
                    <InputLabel id="order-filter">Ordenar por</InputLabel>
                    <Select
                    IconComponent={(props) => <ChevronRight {...props} />}
                        labelId='order-filter'
                        label="Ordenar por"
                    >
                        <MenuItem>x1</MenuItem>
                        <MenuItem>x2</MenuItem>
                        <MenuItem>x3</MenuItem>
                    </Select>
                </FormControl>
            </RootOrderFilter>
        </Root>
    )
}



export default TopFilters