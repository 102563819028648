import React from 'react'
import { Root, RootBottom, SocialImg, logoStyle } from './Footer.style'
import { Grid, Typography } from '@mui/material'
import Logo from '@/components/Logo/Logo'
import { Link } from 'react-router-dom'

const instaSrc = require('@/assets/images/rrss/instagram.webp')
const faceSrc = require('@/assets/images/rrss/facebook.webp')
const linkSrc = require('@/assets/images/rrss/linkedin.webp')
const tiktokSrc = require('@/assets/images/rrss/tiktok.webp')
const youtubeSrc = require('@/assets/images/rrss/youtube.webp')

const Footer = () => {
    return (
        <Root>
            <Grid container spacing={5}>
                <Grid item xs md={3}>
                    <Link to={'/'} className={logoStyle}>
                        <Logo maxWidth={'100%'} />
                    </Link>
                </Grid>
                <Grid item md={3} container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h6'><b>Menú</b></Typography>
                    </Grid>
                    {
                        [
                            {
                                title: 'Propiedades',
                                path: '/',
                            },
                            {
                                title: 'Remates',
                                path: '/auction',
                            },
                            {
                                title: 'Ofrece tu propiedad',
                                path: '/offerProperty',
                            },
                            {
                                title: 'Otros servicios',
                                path: '/otherServices',
                            },
                            {
                                title: 'Contratos gratis',
                                path: '/freeContracts',
                            },
                            {
                                title: 'Agente inmobiliario',
                                path: '/propertyAgent',
                            },
                        ].map(i => (
                            <Grid item xs={12}>
                                <Link to={i.path}>
                                    <Typography variant='body1'>{i.title}</Typography>
                                </Link>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item md={3}>
                    <div>
                        <Typography variant='h6'><b>Contacto</b></Typography>
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <Typography variant='body1'>info@seremata.cl</Typography>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Typography variant='body1'>+569 7140 3094</Typography>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Link to={'/questions'}>
                            <Typography variant='body1'>Preguntas Frecuentes</Typography>
                        </Link>
                    </div>
                </Grid>
                <Grid item md={3}>
                    <div>
                        <Typography variant='h6'><b>Redes sociales</b></Typography>
                    </div>
                    <Grid container spacing={1} mt={2}>
                        {
                            [
                                {
                                    src: instaSrc,
                                    url: 'https://www.instagram.com'
                                },
                                {
                                    src: faceSrc,
                                    url: 'https://www.instagram.com'
                                },
                                {
                                    src: linkSrc,
                                    url: 'https://www.instagram.com'
                                },
                                {
                                    src: tiktokSrc,
                                    url: 'https://www.instagram.com'
                                },
                                {
                                    src: youtubeSrc,
                                    url: 'https://www.instagram.com'
                                }
                            ].map(i => (
                                <Grid item>
                                    <a href={i.url}>
                                        <SocialImg src={i.src} />
                                    </a>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
            <RootBottom>
                <Typography variant='body1'>Se Remata © 2024</Typography>
            </RootBottom>
        </Root>
    )
}

export default Footer