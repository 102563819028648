import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import React from 'react'
import { Root, RootAccordion } from './Filters.style'
import ChevronRight from '@/components/Icons/ChevronRight'

const Filters = () => {
    return (
        <Root>
            <Typography variant='body1' style={{
                marginLeft: 5
            }}><b>FILTROS</b></Typography>
            <RootAccordion>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ChevronRight />}>Fecha subasta</AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="17-01-2024 (10)" />
                            <FormControlLabel control={<Checkbox />} label="18-01-2024 (1)" />
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary expandIcon={<ChevronRight />}>Tipo propiedad</AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Estacionamiento (1)" />
                            <FormControlLabel control={<Checkbox />} label="Industrial (1)" />
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary expandIcon={<ChevronRight />}>Zonas</AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Chile - Zona Norte (15)" />
                            <FormControlLabel control={<Checkbox />} label="Chile - Zona Central (67)" />
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary expandIcon={<ChevronRight />}>Comunas</AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox  />} label="Alto Hospicio (1)" />
                            <FormControlLabel control={<Checkbox  />} label="Antofagasta (2)" />
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            </RootAccordion>
        </Root>
    )
}

export default Filters